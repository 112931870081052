/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { Key, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import maplibregl, { LngLatLike, Map } from 'maplibre-gl';
import { Grid, useTheme } from '@mui/material';
import { v4 } from 'uuid';
import PELIAS_API from '@services/peliasService';
import MapContext from 'contexts/MapContext';

import { clearAllSearchMarkers } from '@utils/map';
import EmptyAddressPlaceholder from '@components/EmptyAddressPlaceholder';
import SearchPlaceholder from '@components/search-placeholder';
import Item from '@components/list/Item';
import useSearchHighlighting from '@hooks/use-search-highlighting';
import { ListItemType } from '@constants';
import SearchBar from '@components/search-bar';
import { IPeliasData, IPeliasFeature } from 'types/peliasData';
import { REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS } from 'constants/map';
import { debounce } from 'lodash-es';
import { getFocusParams } from '@utils/windowParams';
import { SearchParamsOption } from 'ky';

const List = ({
  onHover,
  queryResults,
  isLoading,
  ...props
}: {
  onHover: (item: any) => void;
  queryResults: any;
  isLoading: boolean;
  searchValue: string;
  onClick: (item: any) => void;
}) => {
  const [currentItem, setCurrentItem] = useState(null);
  const highlightSearchedValue = useSearchHighlighting();

  const handleCurrentItemChange = (item: SetStateAction<null>) => {
    setCurrentItem(item);
  };

  return queryResults?.length > 0 ? (
    <Grid px={2} py={1} container gap={1}>
      {queryResults?.map((result: { id: Key | null | undefined }) => (
        <Item
          key={result.id}
          item={result}
          onHover={onHover}
          handleItemClick={props.onClick}
          handleCurrentItemChange={handleCurrentItemChange}
          currentItem={currentItem}
          highlightSearchedValue={highlightSearchedValue}
          searchValue={props.searchValue}
          type={ListItemType.Stop}
        />
      ))}
    </Grid>
  ) : (
    <SearchPlaceholder context='adres' isSearching={isLoading} searchValue={props.searchValue} />
  );
};
const cancelToken = new AbortController();

const AddressLocations = () => {
  const { map } = useContext(MapContext);

  const [searchValue, setSearchValue] = useState('');
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [queryResults, setQueryResults] = useState<IPeliasFeature[] | null>(null);

  const getQueryResults = useCallback(async (searchParams: SearchParamsOption) => {
    try {
      const results = await PELIAS_API.autocomplete<IPeliasData>(searchParams, {
        signal: cancelToken.signal
      });
      setQueryResults(
        results.features.map((item: any) => ({
          ...item,
          id: v4()
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedAutocomplete = useRef(debounce(getQueryResults, 400)).current;

  useEffect(() => {
    if (searchValue) {
      setIsLoading(true);
      const { coords } = getFocusParams();
      const [lng, lat] = coords || [51.9279789, 4.449629];

      debouncedAutocomplete({
        text: searchValue,
        'focus.point.lat': lat,
        'focus.point.lon': lng,
        lang: 'nl-NL',
        'boundary.country': 'NL'
      });
    } else {
      setIsLoading(false);
      setQueryResults(null);
    }

    return () => {
      cancelToken.abort();
    };
  }, [debouncedAutocomplete, getQueryResults, searchValue]);

  const onSearchChange = (value: string) => {
    if (!value) {
      clearAllSearchMarkers();
      setQueryResults(null);
      setSearchValue('');
      return;
    }
    setSearchValue(value);
  };

  const drawMarker = useCallback(
    (item: { properties: { id: number }; geometry: { coordinates: LngLatLike } }) => {
      const glMarker = new maplibregl.Marker({
        draggable: false,
        color: theme.palette.error.main,
        id: item.properties.id ?? 0
      })
        .setLngLat(item.geometry.coordinates)
        .addTo(map as Map);

      glMarker.getElement().classList.add('search-marker');
    },
    [map, theme.palette.error.main]
  );

  const onClick = useCallback(
    (item: { geometry: { coordinates: LngLatLike } }) => {
      clearAllSearchMarkers();
      map?.jumpTo({
        center: item.geometry.coordinates,
        ...(map?.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS && {
          zoom: REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS
        })
      });
      drawMarker({ properties: { id: 0 }, geometry: item.geometry });
    },
    [drawMarker, map]
  );

  const onHover = useCallback(
    (item: any) => {
      if (map && map?.getZoom() < 20) {
        if (item) {
          drawMarker(item);
        } else {
          clearAllSearchMarkers();
        }
      }
    },
    [drawMarker, map]
  );

  useEffect(() => {
    return () => {
      clearAllSearchMarkers();
    };
  }, []);

  return (
    <Grid pt={1}>
      <SearchBar value={searchValue} onChange={onSearchChange} label='Zoek op adres' />
      {isLoading ? (
        <SearchPlaceholder context='adres' isSearching={isLoading} searchValue={searchValue} />
      ) : searchValue.length > 0 ? (
        <List
          onHover={onHover}
          queryResults={queryResults}
          isLoading={isLoading}
          searchValue={searchValue}
          onClick={onClick}
        />
      ) : (
        <EmptyAddressPlaceholder />
      )}
    </Grid>
  );
};

export default AddressLocations;
