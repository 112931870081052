import { USER_AUTH_STATUS } from '@constants';
import { IBuildingAddress, IHub, ILocation } from '@types';
import { getMapLayerInitialState } from '@utils/map/data';
import { atom } from 'jotai';
import { MapLayerType } from 'types/map';
import { IUser } from '../types/index';

const shouldShowSnapToRoadInitialState = JSON.parse(
  localStorage.getItem('mapSettings') as string
)?.shouldShowSnapToRoad;

const mapLayerInitialState = getMapLayerInitialState();

export const userAuthStatusAtom = atom(USER_AUTH_STATUS.PENDING);
export const snackbarAtom = atom({});
export const locationsAtom = atom<ILocation[] | null>([]);
export const isLocationsLoadingAtom = atom(false);
export const buildingAddressesAtom = atom<IBuildingAddress[] | null>(null);
export const buildingsWithAddressesAtom = atom<
  | {
      buildingId: string;
      addresses: IBuildingAddress[];
    }[]
  | null
>(null);
export const buildingPostalCodesAtom = atom<string[] | null>(null);
export const mapSettingsAtom = atom({
  shouldShowSnapToRoad: shouldShowSnapToRoadInitialState ?? false,
  shouldShowLocations: true
});
export const isBuildingSelectModeAtom = atom<boolean | null>(null);
export const selectedBuildingsAtom = atom<string[] | null>(null);
export const mapLayerAtom = atom<MapLayerType>(mapLayerInitialState as MapLayerType);
export const userAtom = atom<IUser | null>(null);
