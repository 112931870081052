import { userAtom, userAuthStatusAtom } from '@store';
import { usePostHog } from 'posthog-js/react';
import PageLoading from '@components/common/PageLoading';
import { APP_ENVIRONMENT, USER_AUTH_STATUS } from '@constants';
import { PropsWithChildren } from 'react';
import authService from '@services/authService';

const AuthWrapper = ({ children }: PropsWithChildren) => {
  const [userAuthStatus, setUserAuthStatus] = useAtom(userAuthStatusAtom);
  const [user, setUser] = useAtom(userAtom);
  const searchParams = new URLSearchParams(window.location.search);
  const hub = searchParams.get('hub') as string;
  const token = searchParams.get('token') as string;
  const isSSO = window.location.pathname.includes('sso');
  const posthog = usePostHog();

  useEffect(() => {
    if (USER_AUTH_STATUS.PENDING === userAuthStatus && !isSSO) {
      authService
        .getUser()
        .then((currentUser) => {
          if (currentUser) {
            setUser(currentUser);
            setUserAuthStatus(USER_AUTH_STATUS.SUCCESS);
          }
        })
        .catch(() => {
          setUserAuthStatus(USER_AUTH_STATUS.FAIL);
        });
    }
  }, [isSSO, setUser, setUserAuthStatus, userAuthStatus]);

  useEffect(() => {
    if (isSSO && USER_AUTH_STATUS.PENDING === userAuthStatus) {
      authService
        .login({
          hub,
          token
        })
        .then(() => {
          authService
            .getUser()
            .then((user) => {
              setUser(user);
              setUserAuthStatus(USER_AUTH_STATUS.SUCCESS);
            })
            .catch(() => {
              setUserAuthStatus(USER_AUTH_STATUS.FAIL);
            });
        })
        .catch(() => {
          setUserAuthStatus(USER_AUTH_STATUS.FAIL);
        });
    }
  }, [hub, isSSO, setUser, setUserAuthStatus, token, userAuthStatus]);

  useEffect(() => {
    window.addEventListener('unauthorized', () => {
      setUserAuthStatus(USER_AUTH_STATUS.FAIL);
      setUser(null);
    });
  }, [setUser, setUserAuthStatus]);

  useEffect(() => {
    if (user) {
      const uniqueIdentifier = user.email;
      posthog?.identify(uniqueIdentifier, {
        hubId: user?.hub.id,
        role: user?.role,
        hubName: user?.hub.name
      });
      posthog?.people.set({
        environment: APP_ENVIRONMENT
      });
    }
  }, [user, posthog]);

  if (USER_AUTH_STATUS.PENDING === userAuthStatus) {
    return <PageLoading />;
  }

  return children;
};

export default AuthWrapper;
