import React from 'react';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import * as yup from 'yup';
import { userAtom, userAuthStatusAtom } from '@store';

import Image from '@components/image';
import logoTypeImage from '@assets/images/logo-type';
import useSnackbar from '@hooks/use-snackbar';
import { USER_AUTH_STATUS } from '@constants';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import authService from '@services/authService';
import Form from './form';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(6).required()
});

const Login = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });
  const navigate = useAdvancedNavigate();
  const [, setUser] = useAtom(userAtom);
  const [userAuthStatus, setUserAuthStatus] = useAtom(userAuthStatusAtom);
  const snackbar = useSnackbar();

  if (userAuthStatus === USER_AUTH_STATUS.SUCCESS) {
    navigate('/');
    return;
  }

  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      setIsLoading(true);
      const response = await authService.login({
        username: data.email,
        password: data.password
      });

      if (response) {
        try {
          const user = await authService.getUser();
          setUser(user);
          snackbar.show('Succesvol ingelogd!');
          setUserAuthStatus(USER_AUTH_STATUS.SUCCESS);
          navigate('/');
        } catch (error) {
          setUserAuthStatus(USER_AUTH_STATUS.FAIL);
          snackbar.error('Onjuist e-mailadres of wachtwoord');
        }
      }
    } catch (error) {
      setUserAuthStatus(USER_AUTH_STATUS.FAIL);
      snackbar.error('Onjuist e-mailadres of wachtwoord');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      sx={{ height: '100vh' }}
      flexDirection='column'
    >
      <Box sx={{ paddingY: 6, textAlign: 'center' }}>
        <Image image={logoTypeImage} alt='Routetitan Logo' />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth='sm'>
          <Paper elevation={1} sx={{ marginBottom: 4 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                px: 6,
                width: 448,
                paddingBottom: 2,
                height: 484
              }}
            >
              <Typography variant='h4' align='center' gutterBottom>
                Login
              </Typography>
              <Typography variant='body1' sx={{ lineHeight: 1.75 }} align='center'>
                to continue to Address Intelligence
              </Typography>
              <Form onSubmit={onSubmit} isSubmitting={isLoading} />
            </Box>
          </Paper>
        </Container>
      </form>
    </Grid>
  );
};

export default Login;
